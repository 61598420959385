import { captureException } from '@sentry/nextjs'
import axios from 'axios'
import Cookies from 'js-cookie'

import { env } from '@/lib/env'

import { EventPayload, UserPayload } from './types'

const BASE_URL = env.NEXT_PUBLIC_TRACKING_URL

export async function hit(event: EventPayload) {
    const sqclid = await generateSqclid()
    try {
        await axios({
            baseURL: BASE_URL,
            url: '/api/v1/events',
            method: 'POST',
            data: {
                sqclid: sqclid,
                data: [event],
            },
        })
    } catch (e) {
        captureException(e)
    }
}

export async function identify(user: UserPayload) {
    if (process.env.NODE_ENV !== 'production') {
        return
    }

    const sqclid = await generateSqclid()
    const fbclid = Cookies.get('_fbclid')
    try {
        await axios({
            baseURL: BASE_URL,
            url: '/api/v1/identify',
            method: 'POST',
            data: {
                sqclid: sqclid,
                userData: {
                    ...user,
                    fbclid: fbclid,
                },
            },
        })
    } catch (e) {
        captureException(e)
    }
}

export function getSqclid() {
    return Cookies.get('_sqclid')
}

export async function generateSqclid() {
    const existingSqclid = Cookies.get('_sqclid')
    if (existingSqclid) {
        return existingSqclid
    }
    try {
        const { data } = await axios({
            method: 'GET',
            baseURL: BASE_URL,
            url: '/api/v1/sqclid',
        })
        Cookies.set('_sqclid', data.sqclid)
        return data.sqclid
    } catch (e) {
        captureException(e)
    }
}
