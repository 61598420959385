import { captureException } from '@sentry/nextjs'

import {
    ButtonTrackClickType,
    EventType,
    GetButtonEventProperties,
    addUserProperties,
    hubspotOptIn,
    identify,
    track,
} from '@/components/tracks'
import { identify as fbIdentify, identify as shortsIdentify } from '@/components/tracks/shorts'
import { createUpdateProfile, fetchUserProfile } from '@/lib/@core/user/user.gateway'

import { extractFirstName, extractLastName } from '../search/search.utils'
import { phoneToNumbers } from '../validator/phoneValidation'
import { BUSINESS_TYPE_OUTRO_VALUE, User } from './user.model'

export async function loadProfile() {
    return fetchUserProfile().then((res) => {
        const u = res.data
        identify!(u?.email)
        shortsIdentify({
            first_name: extractFirstName(u?.name),
            last_name: extractLastName(u?.name),
            email: u?.email,
            phone: u?.phone,
        })
        addUserProperties({
            name: u.name,
            email: u.email,
            phone: u.phone,
            business_type: u.business_type,
            business_type_outro: u.business_type_outro,
            deal_deadline: u.deal_deadline,
        })

        return u
    })
}

export async function createOrUpdateProfile(user: User) {
    if (user.business_type === BUSINESS_TYPE_OUTRO_VALUE && user.business_type_outro === '') {
        return {
            key: 'business_type_outro',
            error: 'Este campo é obrigatório',
        }
    }

    hubspotOptIn(user.email, user.optIn)

    const res = await createUpdateProfile({
        ...user,
        phone: phoneToNumbers(user.phone),
        business_type_outro: user.business_type === BUSINESS_TYPE_OUTRO_VALUE ? user.business_type_outro : '',
    })

    if ('error' in res) {
        return res
    }

    await track(
        EventType.ButtonClick,
        GetButtonEventProperties('profile_created_user', ButtonTrackClickType.CreateNewProfile)
    )

    if (res.data) {
        identify(res.data.email.toLowerCase())
        await fbIdentify({
            first_name: extractFirstName(res.data.name),
            last_name: extractLastName(res.data.name),
            email: res.data.email,
            phone: res.data.phone,
        }).catch(captureException)
        await addUserProperties({
            name: res.data.name,
            phone: res.data.phone,
            email: res.data.email,
            businessType:
                res.data.business_type === BUSINESS_TYPE_OUTRO_VALUE
                    ? `${res.data.business_type}: ${res.data.business_type_outro}`
                    : res.data.business_type,
            dealDeadline: res.data.deal_deadline,
        }).catch(captureException)
    }

    return res.data
}

const userUsecase = { loadProfile }

export default userUsecase
