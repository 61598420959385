import { HttpError } from '@/lib/http/http'
import userHttp from '@/lib/http/user.http'

import { User } from './user.model'

export interface UserResponse {
    data: User
}

export async function fetchUserProfile() {
    return userHttp.get<UserResponse>('api/v1/user/profile', true)
}

const duplicated = {
    idx_phone: 'Este telefone já está em uso.',
    idx_email: 'Este e-mail já está em uso.',
}

export async function createUpdateProfile(body: User) {
    return userHttp
        .post<User, UserResponse>('api/v1/user/profile', body, true)
        .catch(async (error: unknown) => {
            if (error instanceof HttpError) {
                const errMsg = error.data.message

                if (errMsg.includes('idx_phone')) {
                    return {
                        key: 'phone',
                        error: duplicated['idx_phone'],
                    } as const
                }

                if (errMsg.includes('idx_email')) {
                    return {
                        key: 'email',
                        error: duplicated['idx_email'],
                    } as const
                }

                return { error: errMsg } as const
            }

            throw error
        })
        .then((res) => {
            if ('error' in res) {
                return res
            }

            if (!res?.data || Object.keys(res.data).length === 0) {
                return { error: 'Usuário não encontrado' }
            }

            return res
        })
}

export async function getUserSlackChannelId(phone: string) {
    interface SlackChannelResponse {
        id: string
        name: string
    }

    return userHttp.get<SlackChannelResponse>(`api/v1/tickets/channel?phone=${phone}`, true)
}
