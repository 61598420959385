import userHttp from '@/lib/http/user.http'

export interface FavoriteResponse {
    data: {
        [supplyId: string]: boolean
    }
}

export async function fetchFavorites() {
    return userHttp.get<FavoriteResponse>('api/v1/favorites', true)
}

export async function linkFavorite(supplyId: string) {
    return userHttp.post<{ supply_id: string }, FavoriteResponse>('api/v1/favorites', { supply_id: supplyId }, true)
}

export async function unlinkFavorite(supplyId: string) {
    return userHttp.delete<{ supply_id: string }, FavoriteResponse>('api/v1/favorites', { supply_id: supplyId }, true)
}
