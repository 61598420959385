export enum EventTypes {
    VIEW_CONTENT = 'ViewContent',
    SCHEDULE = 'Schedule',
    ADD_TO_CART = 'AddToCart',
    INITIATE_CHECKOUT = 'InitiateCheckout',
    SEARCH = 'Search',
}

export type EventPayload = {
    event_name: EventTypes
    custom_data: {
        [key: string]: string | Array<string> | number
    }
}

export type UserPayload = {
    email: string
    phone: string
    first_name: string
    last_name: string
}
